import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import PageInfo from "../../components/PageInfo";
import Note from "../../components/Note";

import { WarningOutlined, ErrorOutlined } from "@mui/icons-material";

import schema from "../../content/advanced/images/alertes-schema.png";
import alertes from "../../content/advanced/images/alertes.png";

export default function FeatTestsAlerts() {
  return (
    <Layout title="Tests de cohérence et alertes">
      <div className="page-body">
        <PageInfo AdmGl AdmLo />
        <div className="page-title">
          <h1>Tests de cohérence et alertes</h1>
        </div>
        <section>
          <h2 id="fonctionnement-des-tests-et-des-alertes">
            Fonctionnement des tests et des alertes
          </h2>
          <h3>Principe des tests</h3>
          <p>
            Les <b>tests de cohérences</b> sont des paramètres configurables sur
            chaque variable afin de définir des seuils d'
            <b style={{ color: "#ffc100" }}>avertissement</b> ou d'
            <b style={{ color: "#E74C3C" }}>erreur critique</b>. Lorsqu'un
            résultat est entré pour une variable, il est alors comparé aux
            seuils définis. Si un seuil est franchi, il déclenchera alors une{" "}
            <b>alerte</b>.
          </p>
          <p>
            Comme sur le schéma ci-dessous, il est possible de définir des tests
            unilatéraux (
            <em>
              Exemple : le résultat est aberrent uniquement si il est supérieur
              au seuil
            </em>
            ) c'est le cas <b>a. </b>, ou bien des tests bilatéraux (
            <em>
              Exemple : le résultat est aberrant si il est inférieur ou
              supérieur aux seuils fixés autour de la valeur attendue.
            </em>
            ), c'est le cas <b>b.</b>
          </p>
          <img
            src={schema}
            className="centered"
            alt="Fonctionnement des alertes"
            style={{ boxShadow: "none" }}
          />
          <h3>Types d'alertes</h3>
          <p>Il existe deux types d'alertes configurables :</p>
          <table>
            <tr>
              <td>Alerte</td>
              <td>Conséquences</td>
            </tr>
            <tr>
              <td
                style={{
                  gap: 5,
                  textAlign: "center",
                  verticalAlign: "center",
                }}
              >
                <WarningOutlined style={{ color: "#ffc100" }} />{" "}
                <b>Avertissement</b>
              </td>
              <td>Alertes de bas niveau ignorables par un clic sur l'icône</td>
            </tr>
            <tr>
              <td
                style={{
                  gap: 5,
                  textAlign: "center",
                  verticalAlign: "center",
                }}
              >
                <ErrorOutlined style={{ color: "#E74C3C" }} />{" "}
                <b style={{ whiteSpace: "nowrap" }}>Erreur critique</b>
              </td>
              <td>
                Alertes de haut niveau qui devront être traitées pour permettre
                le verrouillage des formulaires
              </td>
            </tr>
          </table>
          <p>
            Il est possible de ne paramétrer que l'un des deux types d'alertes
            au choix.
          </p>
          <p>
            Lorsqu'une alerte est déclenchée, elle apparait à coté du champ
            renseigné dans le dossier patient. La présence d'une ou plusieurs
            alertes est aussi indiquée sur la page <b>Suivi des patients</b>{" "}
            dans la vue détaillée (voir{" "}
            <a href="/fr/advanced/monitoring/">
              <b>Suivi global et détaillé</b>
            </a>
            ).
          </p>
        </section>
        <section>
          <h2 id="définir-une-alerte-pour-une-variable">
            Définir une alerte pour une variable
          </h2>
          <h3>Accéder aux paramètres de variable</h3>
          <p>
            Pour définir une alerte, vous devez d'abord accéder aux paramètres
            de la variable souhaitée. Rendez-vous dans le menu{" "}
            <em>Conception</em> → onglet <em>Édition de l'étude</em>. Passez en
            mode "<b>Édition des modèles</b>", puis cliquez sur l'onglet{" "}
            <b>Variables</b> pour lister l'ensemble des variables de l'étude.
            Cliquez sur la variable à paramétrer, puis dans la fenêtre qui
            s'ouvre basculez sur l'onglet <b>(2) Paramètres avancés</b>.
          </p>
          <h3>Configurer les alertes</h3>
          <p>
            Dans le sous-menu <b>Définir des alertes pour la variable</b>, vous
            avez le choix entre deux types d'alerte (voir la partie{" "}
            <a href="#fonctionnement-des-tests-et-des-alertes">
              <b>Fonctionnement des tests et des alertes</b>
            </a>
            ).
          </p>
          <p>
            Pour le type d'alerte que vous souhaitez, choisissez une condition
            parmi les opérateurs classiques puis définissez la valeur seuil.
            Vous pouvez également définir une autre condition pour encadrer
            bilatéralement la valeur attendue.
          </p>
          <img
            src={alertes}
            className="centered"
            alt="Fonctionnement des alertes"
          />
          <p>
            <em>
              Exemple : <br />
              D'après l'image ci-dessus, l'alerte de type <b>
                Avertissement
              </b>{" "}
              se déclenchera (1) si le poids est supérieur ou égal à 120kg ou
              inférieur ou égal à 50kg.
              <br />
              L'alerte de type <b>Erreur critique</b> ne se déclenchera (2) que
              si le poids est supérieur ou égal à 150kg.
              <br />
              La valeur attendue pour cette variable est donc comprise entre 50
              et 120kg.
            </em>
          </p>
          <Note type="caution">
            Lorsque vous avez paramétré vos alertes, n'oubliez pas d'enregistrer
            la variable pour que les alertes soient appliquées.
          </Note>
        </section>
      </div>
    </Layout>
  );
}
